/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { Link } from "gatsby";

import icon from "../images/icon.png";

const footerStyles = css`
display: flex;
min-height: 200px;
padding-left: 50px;
margin-top: 30px;

`;

const linkStyles = css`
text-decoration: none;
color: black;
margin-right: 50px;
font-weight: lighter;
`

const footerDiv = css`
display: flex;
width: 100%;
margin-bottom: 30px;

div {
    padding-right: 50px;
    display: flex;
    flex-direction: column;

    h1 {
        margin: 0px;
    }

    a {
        text-decoration: none;
        color: #3F6ACC;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
`

const Footer = () => {
    return <span css={footerStyles}>
        <div css={footerDiv}>
            <div>
                <p><b>Bingus Ltd</b> is a company registered in England and Wales with company number <b>13958459</b></p>
            </div>
            <div style={{flexGrow: "1"}}></div>
            <div>
                <h1>Pages</h1>
                <Link to="/">Home</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/blog">Blog</Link>
            </div>
            <div style={{flexGrow: "1"}}></div>
            <div>
                <h1>Contact</h1>
                <a href="mailto:team@bingus.uk">team@bingus.uk</a>
                <a href="https://github.com/bingus-ltd">GitHub</a>
                <a href="https://twitter.com/bingus_ltd">@bingus_ltd</a>
            </div>
        </div>

    </span>
}

export default Footer;
