/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { Link } from "gatsby";

import icon from "../images/icon.png";

const LINKS = [
    {
        to: "/",
        text: "Home"
    },
    {
        to: "/blog",
        text: "Blog"
    },
    {
        to: "/contact",
        text: "Contact"
    }
]

const navbarStyles = css`
display: flex;
padding-left: 50px;
vertical-align: middle;
align-items: center;
margin-top: 30px;

* {
    display: inline;
    vertical-align: middle;
}
`;

const imageStyles = css`
width: 80px;
height: 80px;
padding-right: 20px;
`

const brandStyles = css`
font-size: 2em;
`

const linkStyles = css`
text-decoration: none;
color: black;
margin-right: 50px;
font-weight: lighter;
`

const activeStyle = {
    fontWeight: "400",
    textDecoration: "underline"
}

const NavBar = () => {
    return <span css={navbarStyles}>
        <Link to="/" style={{textDecoration: "none", color: "black"}}>
            <img css={imageStyles} src={icon} width={80} height={80}/>
            <p css={brandStyles}>Bingus LTD</p>
        </Link>
        <div style={{flexGrow: 1}}/>

        {LINKS.map((l) => <Link key={l.to} activeStyle={activeStyle} partiallyActive={l.to != "/"} css={linkStyles} to={l.to}>{l.text}</Link>)}
    </span>
}

export default NavBar;
