/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react";
import { ReactNode } from "react";
import NavBar from "../components/navbar";
import Helmet from "react-helmet";
import logo from "../images/icon.png";
import Footer from "../components/footer";

const DEFAULT_DESCRIPTION = "Bingus LTD is bridging the gap between Agriculture and the Future";

interface PageProps {
    title: string;
    description?: string;
    children: ReactNode;
    author?: string;
}

const globalCss = css`
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500&display=swap');

html, body {
  margin: 0px;
  padding: 0px;
  font-family: Fredoka, sans-serif;
  scroll-behavior: smooth;
}
`;

const Page = (props: PageProps) => {
    return <main>
        <Helmet
            htmlAttributes={{ lang: "en" }}>
            <title>{props.title} &#8226; Bingus LTD</title>
            <meta name="description" content={props.description ? props.description : DEFAULT_DESCRIPTION} />
            <meta name="og:image" content={logo} />
            <meta name="og:title" content={props.title + " • Bingus LTD"} />
            <meta name="twitter:creator" content="@bingus_ltd" />
            <meta name="twitter:description" content={props.description ? props.description : DEFAULT_DESCRIPTION} />
            <meta name="theme-color" content="#616283" />
        </Helmet>
        <Global styles={globalCss} />
        <NavBar />
        {props.children}
        <Footer />
    </main>
}

export default Page;
